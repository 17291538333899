//Auth actions
export const SET_USER = "SET_USER";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAILED = "REGISTER_FAILED";
export const CHANGE_SETTINGS = "CHANGE_SETTINGS";
export const REGISTER_COMPANY_FAILED = "REGISTER_COMPANY_FAILED";
export const REGISTER_COMPANY_SUCCESS = "REGISTER_COMPANY_SUCCESS";
export const CLEAR_NOTIFICATIONS = "CLEAR_NOTIFICATIONS";
export const SAVE_ITEM = "SAVE_ITEM";
export const CLEAR_JUST_CREATED = "CLEAR_JUST_CREATED";
export const SET_IS_AUTHENTICATING = "SET_IS_AUTHENTICATING";


//Errors actions
export const RETURN_ERRORS = "RETURN_ERRORS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";

//Posts actions
export const CREATE_POST = "CREATE_POST";
export const CREATING_POST = "CREATING_POST";
export const CREATE_POST_FAILED = "CREATE_POST_FAILED";
export const GET_POSTS = "GET_POSTS";
export const GETTING_POSTS = "GETTING_POSTS";
export const GET_POSTS_FAILED = "GET_POSTS_FAILED";
export const ADD_COMMENT = "ADD_COMMENT";
export const DELETE_COMMENT = "DELETE_COMMENT";
export const DELETE_POST = "DELETE_POST";
export const UPDATING_POST_LIKE = "UPDATING_POST_LIKE";
export const UPDATE_POST_LIKES = "UPDATE_POST_LIKES";

//Polls action
export const GET_POLLS = "GET_POLLS";
export const GETTING_POLLS = "GETTING_POLLS";
export const GET_POLLS_FAILED = "GET_POLLS_FAILED";
export const CREATE_POLL = "CREATE_POLL";
export const CREATING_POLL = "CREATING_POLL";
export const CREATE_POLL_FAILED = "CREATE_POLL_FAILED";
export const DELETE_POLL = "DELETE_POLL";
export const UPDATING_POLL = "UPDATING_POLL";
export const UPDATE_POLL = "UPDATE_POLL";

//Project actions
export const CREATE_PROJECT = "CREATE_PROJECT";
export const CREATING_PROJECT = "CREATING_PROJECT";
export const CREATE_PROJECT_FAILED = "CREATE_PROJECT_FAILED";
export const GET_PROJECTS = "GET_PROJECTS";
export const GETTING_PROJECTS = "GETTING_PROJECTS";
export const GET_PROJECTS_FAILED = "GET_PROJECTS_FAILED";
export const ADD_PROJECT_COMMENT = "ADD_PROJECT_COMMENT";
export const DELETE_PROJECT_COMMENT = "DELETE_PROJECT_COMMENT";
export const DELETE_PROJECT = "DELETE_PROJECT";
export const UPDATING_PROJECT = "UPDATING_PROJECT";
export const UPDATE_PROJECT = "UPDATE_PROJECT";

//others
export const RESET_CREATED = "RESET_CREATED"